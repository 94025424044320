import { useMemo, memo, useState, useEffect } from 'react';
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridFooterContainer,
  GridFooter,
  GridOverlay,
  GridPaginationModel,
} from '@mui/x-data-grid';
import {
  ConsumptionFilters,
  ConsumptionItemData,
  SummaryTotalInfo,
} from '../../domain/types';
import { QueryStatus } from '../Messaging';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { MonetaryDisplay, monetaryFormat } from '../MonetaryDisplay';
import { useChartSeriesColors } from '../../theme/useChartSeriesColors';
import { getResolvedArrayValue } from '../../utils/arrayUtils';
import { PercentageDisplay } from '../PercentageDisplay';
import { QueryLoader } from '../Messaging/QueryStatus';
import { ReportGridContainer } from '../ReportGridContainer';

export interface ConsumptionItemSummariesGridProps {
  data?: ConsumptionItemData;
  totals?: SummaryTotalInfo;
  filters: ConsumptionFilters;
  loading: boolean;
  error: any;
}

const renderMonetary = (value: number | string, currency?: string) => {
  return <MonetaryDisplay value={Number(value)} currency={currency} />;
};

const renderDiscountPercentage = (
  discountPercentage: number,
  discountAmount: number,
  grossCost: number
) => {
  // don't show anything if values are not valid numbers
  if (isNaN(discountPercentage) || isNaN(discountAmount) || isNaN(grossCost)) {
    return <span />;
  }

  // if grossCost would be displayed as 0.00 then do not show anything
  // e.g. grossCost = 0.0008 and discountAmount = 0.0004 then both would be displayed as 0.00 to the user
  // but the discountPercentage would be displayed as 50%
  if (grossCost < 0.005) {
    return <span />;
  }

  // if grossCost and discountAmount are both above 0 and displayed as the same number then always show the discount as 100%
  // e.g. grossCost = 0.008 and discountAmount = 0.006 then both are displayed as 0.01 to the user but
  // the discountPercentage would be displayed as 75%
  if (monetaryFormat(grossCost) === monetaryFormat(discountAmount)) {
    return <span>100%</span>;
  }

  const formattedDiscountPercentage = (
    <PercentageDisplay value={discountPercentage} />
  );
  return <span>{formattedDiscountPercentage}</span>;
};

const SUMMARY_GRID_PAGE_SIZE = 10;

export const ConsumptionItemSummariesGrid = memo(
  ({
    data,
    totals,
    filters,
    loading,
    error,
  }: ConsumptionItemSummariesGridProps) => {
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>(
      {
        page: 0,
        pageSize: 10,
      }
    );

    const colors = useChartSeriesColors();
    const classes = useStyles();
    const currency = data?.currency;
    const itemType = filters.breakdown;
    // the grid requires ID values:
    const augmentedData =
      data?.items.map((dataItem, id) => ({
        ...dataItem,
        id,
      })) ?? [];

    const columns: GridColDef[] = useMemo<GridColDef[]>(() => {
      const itemTypes = {
        overall: 'Overall',
        project: 'Project',
        service: 'Service',
        sku: 'Sku',
        project_group: 'Project Group',
        customer: 'Customer',
        billing_account: 'Billling Account',
        project_label: 'Project Label',
        resource_label: 'Resource Label',
      };

      const cols = [
        {
          renderHeader: () => <span></span>,
          field: 'id',
          renderCell: ({ value }: GridRenderCellParams) => {
            return (
              <FiberManualRecordIcon
                style={{
                  fill: `${getResolvedArrayValue(colors, value as number)}`,
                }}
              />
            );
          },
          sortable: false,
          hideSortIcons: true,
          width: 50,
        },
        {
          headerName: itemTypes[itemType] ?? 'Item',
          field: 'item',
          flex: 0.2,
        },
        {
          headerName: 'Gross cost',
          field: 'gross_cost',
          flex: 0.2,
          renderCell: ({ value }: GridRenderCellParams) =>
            renderMonetary(value, currency),
        },
        {
          headerName: 'Discount',
          field: 'discount',
          flex: 0.2,
          renderCell: ({ value }: GridRenderCellParams) =>
            renderMonetary(value, currency),
        },
        {
          headerName: 'Discount %',
          field: 'discount_percentage',
          renderCell: ({ value, row }: GridRenderCellParams) => {
            return renderDiscountPercentage(
              Number(value),
              Number(row.discount),
              Number(row.gross_cost)
            );
          },
          flex: 0.2,
        },
        {
          headerName: 'Net cost',
          field: 'net_cost',
          flex: 0.2,
          renderCell: ({ value }: GridRenderCellParams) =>
            renderMonetary(value, currency),
        },
      ];

      if (itemType === 'project') {
        cols.splice(2, 0, {
          headerName: 'Project Name',
          field: 'project_name',
          flex: 0.2,
        });
      }

      if (itemType === 'sku') {
        cols.splice(2, 0, {
          headerName: 'Sku ID',
          field: 'gcp_sku_id',
          flex: 0.2,
        });
      }

      if (itemType === 'service') {
        cols.splice(2, 0, {
          headerName: 'Service ID',
          field: 'gcp_service_id',
          flex: 0.2,
        });
        cols.splice(3, 0, {
          headerName: 'Service Type',
          field: 'service_type',
          flex: 0.2,
        });
      }

      return cols.map((col) => ({
        ...col,
        disableClickEventBubbling: true,
        disableColumnMenu: true,
      }));
    }, [colors, currency, itemType]);

    const rowCount = loading ? undefined : data?.items.length ?? 0;

    useEffect(() => {
      setPaginationModel({
        page: 0,
        pageSize: SUMMARY_GRID_PAGE_SIZE,
      });
    }, [filters]);

    return (
      <ReportGridContainer>
        {error ? (
          <GridOverlay>
            <QueryStatus isError={true} />
          </GridOverlay>
        ) : (
          <DataGrid
            className={classes.grid}
            // data
            columns={columns}
            rows={augmentedData}
            // state
            loading={loading}
            paginationModel={paginationModel}
            onPaginationModelChange={(model: GridPaginationModel) =>
              setPaginationModel(model)
            }
            slots={{
              footer: () =>
                totals ? (
                  <GridFooterContainer>
                    <div
                      className={classes.totals}
                      data-testid="summaries-totals"
                    >
                      <strong>Total Gross cost:</strong>{' '}
                      <MonetaryDisplay
                        value={totals.gross}
                        currency={currency}
                      />
                      , <strong>Total Net cost:</strong>{' '}
                      <MonetaryDisplay value={totals.net} currency={currency} />
                    </div>
                    <GridFooter />
                  </GridFooterContainer>
                ) : null,
              loadingOverlay: () => (
                <GridOverlay>
                  <QueryLoader />
                </GridOverlay>
              ),
            }}
            columnBuffer={columns.length}
            // layout
            density="compact"
            autoHeight
            // pagination
            pageSizeOptions={[10, 25, 50, 100]}
            rowCount={rowCount}
            // sort order
            sortModel={[
              {
                field: 'net_cost',
                sort: 'desc',
              },
            ]}
          />
        )}
      </ReportGridContainer>
    );
  }
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'relative',
      width: '100%',
      minHeight: '300px',
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    wrapperContainer: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      position: 'absolute',
      width: '100%',
      minHeight: '100%',
      paddingBottom: 16,
    },
    gridContainer: {
      background: 'white',
      width: '100%',
      minHeight: '100%',
      flexGrow: 1,
      border: `1px solid ${theme.palette.grey[300]}`,
      borderRadius: 8,
    },
    grid: {
      '&.MuiDataGrid-root': {
        border: 'none',
      },
    },
    totals: {
      marginLeft: theme.spacing(2),
    },
  })
);
